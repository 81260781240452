import knight from "../assets/img/knight.png";
import polarLogo from "../assets/img/polar-logo.svg";
import polarfiText from "../assets/img/polarfi-text.svg";
import { useEffect } from "react";
import { Element } from "react-scroll";

export default function Upcoming() {
  useEffect(() => {
    const handleScroll = () => {
      const viewportHeight = window.innerHeight;

      const imageUpcoming = document.getElementById("image_upcoming");

      if (imageUpcoming.getBoundingClientRect().top < viewportHeight) {
        imageUpcoming.classList.add("animate-fadeInUpScroll");
      }

      const stackingUpcoming = document.getElementById("stacking_upcoming");

      if (stackingUpcoming.getBoundingClientRect().top < viewportHeight) {
        stackingUpcoming.classList.add("animate-fadeInLeftScroll");
      }

      const revenueUpcoming = document.getElementById("revenue_upcoming");

      if (revenueUpcoming.getBoundingClientRect().top < viewportHeight) {
        revenueUpcoming.classList.add("animate-fadeInRightScroll");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <section>
      <Element
        name="image_upcoming"
        id="image_upcoming"
        className="text-center"
      >
        <div className="relative inline-block">
          <img src={knight} alt="knight RPG" className="mx-auto object-cover" />
          <div className="absolute top-0 left-[-90px]">
            <div className="bg-black bg-opacity-80 text-white font-bold py-3 px-10 rounded">
              <div className="flex">
                <img src={polarLogo} alt="Polar Logo" />
                <div className="text-left ml-5">
                  <img src={polarfiText} alt="polarFi" />
                  <span className="block uppercase">Finance</span>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-24 right-[-50px]">
            <div className="bg-slate-900 bg-opacity-80 text-sky-500 font-bold py-3 px-10 rounded-2xl">
              <span>+600 $FROST</span>
            </div>
          </div>
          <div className="absolute bottom-7 right-[-100px]">
            <div className="bg-slate-900 bg-opacity-80 text-sky-500 font-bold py-3 px-10 rounded-2xl">
              <span>+48 $FROST</span>
            </div>
          </div>
        </div>
      </Element>

      <div className="flex flex-col lg:flex-row justify-center items-center text-center lg:text-left">
        <Element
          name="stacking_upcoming"
          id="stacking_upcoming"
          className="lg:w-1/2 px-4"
        >
          <h3 className="font-bold text-white text-3xl">
            PolarFi NFT Staking (Coming Soon)
          </h3>
          <p className="text-slate-500 pb-10 pt-3">
            PolarFi's NFT staking introduces a unique way to earn rewards. Stake
            your NFTs with us and unlock a world of possibilities. Enjoy
            seamless integration, generous returns, and be part of a growing
            community that values simplicity and profitability. Stake, earn, and
            elevate your crypto experience with PolarFi.
          </p>
        </Element>
        <Element
          name="revenue_upcoming"
          id="revenue_upcoming"
          className="lg:w-1/2 px-4"
        >
          <h3 className="font-bold text-white text-3xl">
            PolarFi Revenue Share (Coming Soon)
          </h3>
          <p className="text-slate-500 pb-10 pt-3">
            PolarFi's revenue share model is your direct ticket to earnings. By
            participating, you become a stakeholder in our success, sharing in
            the rewards generated by the platform. It's a straightforward and
            rewarding way to be part of the financial growth at PolarFi,
            creating a symbiotic relationship between the platform's success and
            your own financial prosperity. Join us for a seamless journey where
            your success aligns with ours.
          </p>
        </Element>
      </div>
    </section>
  );
}
